@import url('https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap');

$black-coffee: #32292f;
$tea-green: #def6ca;
$african-violet: #a67db8;
$coral: #ff8966;
$cg-blue: #087ca7;


body {
  font-family: 'Goudy Bookletter 1911', serif;
}

h1 {
  font-size: 5rem;
  font-weight: 800;
  position: relative;
  z-index: 1;
  display: inline-block;

  &:after {
    content: '';
    height: 2rem;
    width: 100%;
    background: rgba($coral, 0.4);
    bottom: 0rem;
    display: block;
    position: absolute;
    left: 5%;
    z-index: -1;
  }
}

h5 {
  font-weight: 400;
  font-size: 1.7rem;
}

p {
  font-size: 1.2rem;
}

a {
  color: $cg-blue;
  font-weight: 500;
}

// Nav
nav.navbar {
  position: absolute;
  top: 0;
  right: 0;
  height: 11vh;
  z-index: 1000;
  a.nav-link {
    color: $black-coffee !important;
    font-weight: 500;
    font-size: 1.2rem;
    &:hover {
      color: $cg-blue !important;
      text-shadow: 0px 0px 2px rgba($black-coffee, 0.3);
    }
  }
  &:after {
    content: '';
    height: 1rem;
    background: rgba(166, 125, 184, 0.4);
    bottom: 0rem;
    display: block;
    position: absolute;
    left: 6%;
    z-index: -1;
    bottom: 2rem;
    width: 92%;
  }
}

// Home
section.home {
  background: $tea-green;
  color: $black-coffee;
  height: 100vh;
  img {
    height: 90vh;
    margin-top: 10vh;
  }

  .content-wrapper {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 15vw;
  }
}

// writing
section.writing {
  background: $tea-green;
  min-height: 100vh;
  padding-top: 15vh;
  font-size: 1.2rem;

  h1 {
    &:after {
      content: '';
      height: 2rem;
      width: 100%;
      background: rgba($coral, 0.4);
      bottom: 0rem;
      display: block;
      position: absolute;
      left: 15%;
      z-index: -1;
    }
  }

  a {
    color: $black-coffee;
  }

  a.link {
    color: $cg-blue;
  }

  .card {
    border: none;
    border-radius: 0px;
    box-shadow: 0px 0px 8px rgba($african-violet, 0.5);
    .card-img {
      border-radius: 0;
    }
    .card-title {
      font-weight: 600;
    }
    .outlet {
      color: $cg-blue;
    }
    .award {
      color: $african-violet;
    }
  }
}

// standup
section.standup {
  background: $tea-green;
  color: $black-coffee;
  padding-top: 15vh;
  min-height: 100vh;
  overflow: hidden;
  h1 {
    &:after {
      content: '';
      height: 2rem;
      width: 100%;
      background: rgba($african-violet, 0.4);
    }
  }

  .quotes {
    position: relative;
    margin-top: 60px;
    z-index: 2;
    &:before {
      content: '\201C';
      font-size: 15rem;
      position: absolute;
      top: -7rem;
      left: -5%;
      opacity: 25%;
      color: $coral;
      z-index: -1;
    }
    &:after {
      content: '\201D';
      font-size: 15rem;
      position: absolute;
      bottom: -12rem;
      right: -5%;
      opacity: 10%;
      color: $coral;
      z-index: -1;
    }
  }
}

// contact
section.contact {
  background: $tea-green;
  color: $black-coffee;
  padding-top: 15vh;
  min-height: 100vh;
  h1 {
    &:after {
      content: '';
      height: 2rem;
      left: 15%;
      width: 100%;
      background: rgba($cg-blue, 0.4);
    }
  }
  img {
    max-width: 100%;
    z-index: 1;
  }
}

section.projects {
  background: $tea-green;
  color: $black-coffee;
  padding-top: 15vh;
  min-height: 100vh;
  h1 {
    &:after {
      content: '';
      height: 2rem;
      left: 15%;
      width: 100%;
      background: rgba($cg-blue, 0.4);
    }
  }
  img {
    max-width: 100%;
    z-index: 1;
  }
}

.static {
  position: relative;
}

.drigible {
  position: absolute;
  animation: orbit 10s infinite ease-in-out;
  width: 120px;
  height: auto !important;
}

.border-top {
  border-top: 1px solid $cg-blue!important;
}

.animate__delay-1-2s {
  animation-delay: .5s;
}

// Animations
@keyframes orbit {
  0%   {left: 0px; z-index: 10; width: 120px; top: 60px;}
  25%  {width: 145px;}
  50%  {left: 80%; z-index: 0; width: 120px; top: 120px;}
  75%  {width: 105px;}
  100% {left: 0px; width: 120px; top: 60px;}
}

@-webkit-keyframes flip-2-hor-top-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg);
            transform: translateY(-100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}
@keyframes flip-2-hor-top-1 {
  0% {
    -webkit-transform: translateY(0) rotateX(0);
            transform: translateY(0) rotateX(0);
    -webkit-transform-origin: 50% 0%;
            transform-origin: 50% 0%;
  }
  100% {
    -webkit-transform: translateY(-100%) rotateX(-180deg);
            transform: translateY(-100%) rotateX(-180deg);
    -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
  }
}

// mobile
@media (max-width: 375px) {
  nav.navbar a.nav-link {
    font-size: 1rem;
  }
  section.home {
    h1 {
      font-size: 4rem;
    }
    h5 {
      font-size: 1.4rem;
    }
    .row {
      height: 100vh;
    }
    .content-wrapper {
      margin-left: 0;
    }
    img {
      height: 75vh;
      margin-top: 10vh;
      right: 0;
      position: absolute;
      bottom: 0;
    }
    img.drigible {
      position: absolute;
      animation: orbit 10s infinite ease-in-out;
      width: 60px !important;
      height: auto !important;
    }
    @keyframes orbit {
      0%   {left: -40px; z-index: 10; width: 120px; top: 60px;}
      25%  {width: 145px;}
      50%  {left: 80%; z-index: 0; width: 120px; top: 120px;}
      75%  {width: 105px;}
      100% {left: -40px; width: 120px; top: 60px;}
    }
  }
}
@media (min-width: 376px) and (max-width: 768px) {
  section.home {
    h1 {
      font-size: 4rem;
    }
    h5 {
      font-size: 1.4rem;
    }
    img {
      height: 90vh;
      margin-top: 10vh;
      right: 0;
      position: absolute;
      bottom: 0;
    }
    img.drigible {
      position: absolute;
      animation: orbit 10s infinite ease-in-out;
      width: 60px !important;
      height: auto !important;
    }
    .row {
      height: 100vh;
    }
    .content-wrapper {
      margin-left: 0;
    }
    @keyframes orbit {
      0%   {left: -90px; z-index: 10; width: 120px; top: 60px;}
      25%  {width: 145px;}
      50%  {left: 80%; z-index: 0; width: 120px; top: 120px;}
      75%  {width: 105px;}
      100% {left: -90px; width: 120px; top: 60px;}
    }
  }
}

@media (max-width: 375px) {
  section.standup {
    img {
      max-width: 100vw;
    }
  }
}
